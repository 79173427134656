$COLOR_BG_1: rgba(#243B55, 0.75);
$COLOR_BG_2 : rgba(#141E30, 0.65);

.Stats{
	$desktopWidth : 25%;
	$headerInfoHeight : 80px;
	$headerListHeight : 150px;

	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	pointer-events: none;
    width: $desktopWidth;

	// +desktop() {
	// 	width: $desktopWidth;
		
	// }

	// +mobile() {
	// 	left: 0;
	// }

	 .content {
		position: relative;
		height: 100%;
		width: 100%;
		pointer-events: auto;
		background: rgba(#fff, 0.925);
		overscroll-behavior: contain;
		transition-property: transform opacity;
		transition-duration: 0.25s;
        width: 100%;
		background-color: #F8F9FB;

		// +desktop() {
		// 	width: 100%;
		// 	background-color: #F8F9FB;
		// 	//box-shadow: -4px 0px 12px 2px rgba(#000, 0.5);
		// }

		// +mobile() {
		// 	width: 100%;
		// }

		&:not(.visible) {
			transform: translateX(100%);
			opacity: 0;
		}

		.chat-and-participant{
			position : relative;
			margin : 7px;
			background-color: #E9EAEC;
			height : calc(100% - 14px);
			border-radius : 8px 8px 0 0;
			.parent-container{
				height : calc(100% - 14px);
				background-color : white;
				margin : 7px;
				position : relative;
				top : 7px;
				.status-container{
					min-height : 36px;
					display : flex;
					align-items: center;
    				justify-content: space-between;
					padding-left : 10px;
					.addParticipants{
						height: 20px;
    					width: 20px;
						background-repeat : no-repeat;
						background-size : cover;
						background-image: url('../../../assets/icons/info-icon.svg');
						cursor: pointer;
					}
					.timer{
						font-size: 14px; 
						color : #363636; 
						font-weight: 600;
						    display: flex;
    						align-items: center;
						&::before{
							content : url(../../../assets/icons/Clock-icon.svg);
							padding-top : 2px;
						}
					}
					.icon{ 
						height: 20px;
    					width: 20px;
						background-repeat : no-repeat;
						background-size : cover;
						&.mic-off {
							background-image: url('../../../assets/icons/Add-Participate1.svg');
						}

						&.webcam-off {
							background-image: url('../../../assets/icons/icon_video_black_off.svg');
						}
						&.mic-on{
							background-image : url('../../../assets/icons/icon_mic_black_on.svg');
						}
						&.webcam-on{
							background-image : url('../../../assets/icons/Video-icon-black.svg');
						}
					}
				}
			}
		}

		 .header {
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			right: 0;
			padding: 0 28px;
			background: linear-gradient(
				to bottom,
				#fff,
				#fff,
				rgba(#fff, 0.9),
				rgba(#fff, 0));

			 .info {
				height: $headerInfoHeight;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				 .close-icon {
					flex: 0 0 auto;
					width: 28px;
					height: 28px;
					margin-left: -6px;
					margin-right: 20px;
					cursor: pointer;
					background-position: left;
					background-size: 100%;
					background-repeat: no-repeat;
					background-image: url('../../../assets/icons/icon_close_black.svg');
					opacity: 0.75;

					&:hover {
						opacity: 1;
					}
				}

				 h1 {
					flex: 100 100 auto;
					text-transform: uppercase;
					color: #444;
					font-size: 14px;
					font-weight: 600;
					text-align: right;

					// textWithEllipsis();
				}
			}

			 .list {
				height: $headerListHeight;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-end;

				p {
					margin-bottom: 5px;
					color: $COLOR_BG_1;
					font-size: 14px;
					font-weight: 400;
					user-select: none;

					 a {
						text-decoration: none;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: 600;
						color: lighten($COLOR_BG_1, 35%);
						cursor: pointer;

						&.disabled {
							opacity: 0.5;
							cursor: not-allowed;
						}
					}
				}
			}
		}

		 .stats {
			padding: 0 28px;
			height: 100%;
			overflow: auto;

			 .items {
				margin-bottom: 40px;

				// AppearFadeIn(150ms);

				&:after {
					$margin : $headerInfoHeight + $headerListHeight;

					content: '';
					display: block;
					visibility: hidden;
					margin-bottom: (-1 * $margin);
				}

				 h2 {
					margin-bottom: 20px;
					text-transform: uppercase;
					color: $COLOR_BG_2;
					font-size: 13px;
					font-weight: 600;
					text-align: center;

					// textWithEllipsis();

					&:before {
						$margin : $headerInfoHeight + $headerListHeight;

						content: '';
						display: block;
						visibility: hidden;
						height: $margin;
					}
				}

				 .item {
					padding-bottom: 10px;
					margin-bottom: 20px;
					border-bottom: 1px solid #e5e5e5;

					 .line {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 8px;

						 .key {
							flex: 0 0 auto;
							width: 38.5%;
							color: $COLOR_BG_2;
							font-size: 11px;
							font-weight: 400;
							word-break: break-all;
							text-align: right;
						}

						 .value {
							flex: 0 0 auto;
							width: 58.5%;
							color: lighten($COLOR_BG_1, 35%);
							font-size: 11px;
							font-weight: 400;
							word-break: break-all;
							text-align: left;
						}
					}
				}
			}
		}
	}
}
.form-fields{
    height: 34px;
    width: calc(100% - 10px);
    text-decoration: none;
    border-radius: 6px;
	margin-top: 16px;
    font-size: 16px;
    margin-right: 20px; 
    padding: 0 12px;
    border: 1px solid darkgray;
    box-shadow: 0px 0px 1px rgb(0 0 0);

}
.setButton{
	
	bottom: 20px;
	right: 11px;
	border-radius: 5px !important;
	width: 124px !important;
}
.setInviteButton{
	position: absolute;
	bottom: 10px;
	right: 11px;
	border-radius: 5px !important;
	width: 124px !important;
	&:disabled{
		cursor: not-allowed;
	}
}
.openMeetingData{
	display: flex;
	font-size: 17px;
	margin-bottom: 10px;

}