.button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97px;
    height: 34px;
    border-radius: 37px;
    font-size: 14px;
    text-align: center;
    background-color: #0197D6;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border: none;
    margin-right: 10px;
    color: white;
  }
  