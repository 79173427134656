.Me {
  position: relative;
  height: 100%;
  width: 100%;

  .pinButton {
    cursor: pointer;
    position: absolute;
	  z-index: 5;
	  right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: fit-content;
    width: fit-content;
    padding: 3px 4px 3px 6px;
    border-bottom-left-radius: 16px;

    .pinIcon {
      height: 18px;
      width: 18px;
    }

    .pinIcon:not(:last-child){
      margin-right: 6px;
    }
  }

  > .controls {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;

    > .button {
      flex: 0 0 auto;
      margin: 4px;
      margin-left: 0;
      border-radius: 2px;
      pointer-events: auto;
      background-position: center;
      background-size: 75%;
      background-repeat: no-repeat;
      background-color: rgba(#000, 0.5);
      cursor: pointer;
      transition-property: opacity, background-color;
      transition-duration: 0.15s;
      width: 28px;
      height: 28px;
      opacity: 0.85;

      &:hover {
        opacity: 1;
      }

      // +desktop() {
      // 	width: 28px;
      // 	height: 28px;
      // 	opacity: 0.85;

      // 	&:hover {
      // 		opacity: 1;
      // 	}
      // }

      // +mobile() {
      // 	width: 26px;
      // 	height: 26px;
      // }

      &.unsupported {
        pointer-events: none;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.on {
        background-color: rgba(#fff, 0.85);
      }

      &.mic {
        &.on {
          background-image: url("../../../../assets/icons/icon_mic_black_on.svg");
        }

        &.off {
          background-image: url("../../../../assets/icons/icon_mic_white_off.svg");
          background-color: rgba(#d42241, 0.7);
        }

        &.unsupported {
          background-image: url("../../../../assets/icons/icon_mic_white_unsupported.svg");
        }
      }

      &.webcam {
        &.on {
          background-image: url("../../../../assets/icons/icon_webcam_black_on.svg");
        }

        &.off {
          background-image: url("../../../../assets/icons/icon_webcam_white_on.svg");
        }

        &.unsupported {
          background-image: url("../../../../assets/icons/icon_webcam_white_unsupported.svg");
        }
      }

      &.change-webcam {
        &.on {
          background-image: url("../../../../assets/icons/icon_change_webcam_black.svg");
        }

        &.unsupported {
          background-image: url("../../../../assets/icons/icon_change_webcam_white_unsupported.svg");
        }
      }

      &.share {
        &.on {
          background-image: url("../../../../assets/icons/icon_share_black_on.svg");
        }

        &.off {
          background-image: url("../../../../assets/icons/icon_share_white_on.svg");
        }

        &.unsupported {
          background-image: url("../../../../assets/icons/icon_share_white_unsupported.svg");
        }
      }

      &.close {
        background-image: url("../../../../assets/icons/icon_close_black.svg");
      }

      &.user {
        // background-image:url('../../../assets/icons/profile-user.png');
      }
    }
  }
}

.active-speaker {
  border-right: 4px solid #0197d6;
  border-radius: 7px 7px 0 0;
}

.not-active-speaker {
  border-right: 4px solid #fff;
  border-radius: 7px 7px 0 0;
}

.Me:last-child {
  border-right: 0;
  border-radius: 0;
}

.Me:not(:last-child){
	border-radius: 0;
}
