* {
	box-sizing: border-box;
	outline: none;
	font-family : sans-serif;
	scroll-behavior : smooth;
	::-webkit-scrollbar {
		width : 2px;
	};
	::-webkit-scrollbar-track{
		background-color : lightgray;
		border-radius : 3px
	}
	::-webkit-scrollbar-thumb{
		background :  #0197D6;
		border-radius : 3px
	}
}

html{
	height: 100%;
	margin:0;
	box-sizing: border-box;
	// background-image: url('/resources/images/body-bg-2.jpg');
	// background-attachment: fixed;
	// background-position: center;
	// background-size: cover;
	// background-repeat: no-repeat;
	background: #141E30;
	background: linear-gradient(to top, #243B55, #141E30);
	font-family: 'sans-serif';
	font-weight: 300;
	scroll-behavior : smooth;
	font-size: '16px';
}

body {
	height: 100%;
	margin:0;
	// height: 100%;
	width: 100%;
	// margin:0;
	overflow:hidden; 
	background-color: #fff;
}

#root{
	height: 100%;
	width: 100%;
	margin:0;
	overflow:hidden; 
}

code {
  
}

.wavelength1{
	padding-left : 18px;
	z-index: 1;
	right: 12px;
	top: 8px;
	@keyframes quiet {		
		25%{
		transform: scaleY(.6);
		}
		50%{
transform: scaleY(.4);
		}
		75%{
transform: scaleY(.8);
		}
	}

@keyframes normal {
25%{
transform: scaleY(1);
}
50%{
transform: scaleY(.4);
}
75%{
transform: scaleY(.6);
}
}
@keyframes loud {
25%{
transform: scaleY(1);
}
50%{
transform: scaleY(.2);
}
75%{
transform: scaleY(1.2);
}
}
		.boxContainer{
		  display: flex;
		  justify-content: space-between;
		  height: 15px;
		width: 19px;
		}

.box{
transform: scaleY(.2);
height: 100%;
width: 3px;
background: #7fff00;
animation-duration: 1.2s;
animation-timing-function: ease-in-out;
animation-iteration-count: infinite;
border-radius: 0px;
}

.box1{
animation-name: quiet;
}

.box2{
animation-name: normal;
}

.box3{
animation-name: quiet;
}

.box4{
animation-name: loud;
}

.box5{
animation-name: quiet;
}
	}
