.joiningRoom {
    position : relative;
    height : 100%;
    width : 100%;
    background-color:white;

    > header{
        height : 60px;
        width : 100%;
        padding : 0 30px; 
        background-image : linear-gradient(white,#F5F5F5);
        display : flex;
        align-items : center;
    }

    > .welcome-body{
        position: relative;
        height : calc(100% - 60px - 30px);
        width : 100%;
        background-color : #fefefe;
        padding : 0 30px;

        > .userName {
            height : 60px;
            width : 100%;
            font-size : 18px;
            display : flex;
            align-items : center;
            text-align : left;
            color : #675B5B;
        }
        > .parent-container{
            height : calc(100% - 60px - 105px);
            display : flex;
            > .lhs-container{
                width : 60%;
                box-shadow : 0 3px 6px rgba(0,0,0,0.16);
                border-radius : 15px;
                position : relative;
                > .video-conainer{
                    position : relative;
                    height : calc(100% - 42px);
                    width : calc(100% - 42px);
                    border-radius : 8px;
                    margin : 21px;
                    z-index : 1;
                    background-color : rgba(67,67,67,0.24);
                    video{
                        position : absolute;
                        width : 100%;
                        height : 100%;
                        object-fit : fill;
                        border-radius : 8px;
                    }
                    >.message{
                        text-align: center;
                        font-size: 18px;
                        font-weight : 600;
                        top: 50%;
                        position: absolute;
                        width: 100%;
                    }
                    >.error404{
                        text-align: center;
                        font-size: 18px;
                        font-weight : 600;
                        top: 43%;
                        position: relative;
                        text-align : center;
                        width: 100%;
                    }
                    >.icons-container{
                        z-index : 3;
                        >.left-audio{
                            width: 48px;
                            height : 48px;
                            border-radius : 50%;
                            background-color : rgba(49,49,49,0.88);
                            background-repeat: no-repeat;
                            background-position: center;
                         >.left-image{
                           height: 30px;
                           width: 46px;
                           background-repeat: no-repeat;
                           background-position: center;
                           position: relative;
                           top: 7px;
                           background-image: url('../../../assets/icons/LSMeet_Voice-enable.svg');
                            
                        }
                        >.left-image-disable{
                             height: 30px;
                           width: 46px;
                           background-repeat: no-repeat;
                            background-position: center;
                            position: relative;
                            top: 7px;
                            background-image: url('../../../assets/icons/LSMeet_Voice-disable.svg');
                         
                        }
                        }

                       >.right-video{
                            width: 48px;
                            height : 48px;
                             border-radius : 50%;
                            background-color : rgba(49,49,49,0.88);
                            background-repeat: no-repeat;
                            background-position: center;
                        >.right-image{
                            height: 30px;
                            width: 46px;
                            background-repeat: no-repeat;
                            background-position: center;
                            position: relative;
                            top: 7px;
                            background-image: url('../../../assets/icons/LSMeet_Video-Enable.svg');
                            
                        }
                        >.right-image-disable{
                           height: 30px;
                           width: 46px;
                           background-repeat: no-repeat;
                            background-position: center;
                            position: relative;
                            top: 7px;
                            background-image: url('../../../assets/icons/LSMeet_Video-disable.svg');
                           
                        }
                       }
                    }
                    >.more-div{
                        position : absolute;
                        width: 48px;
                        height: 48px;
                        right: 25px;
                        bottom: 41px;
                        z-index : 5;
                        >.more-icon{
                            width: 48px;
                            height : 48px;
                            background-image: url('../../../assets/icons/More-icon.svg');
                            border-radius : 50%;
                            background-color : rgba(49,49,49,0.88);
                            background-repeat: no-repeat;
                            background-position: center;                            
                        }
                    }
                    .dropbtn{
                        background-color : red;
                        color : white;
                        padding: 16px;
                        font-size: 16px;
                        border : none;
                        cursor : pointer;
                    }
                    .dropdown{
                        position : relative;
                        display : inline-block;

                    }
                    .dropdown-content{
                        display : none;
                        position : absolute;
                        background-color : pink;
                        min-width : 160px;
                        overflow : auto;
                        z-index : 1;

                    }
                    .dropdown-content span{
                        color : black;
                        display : block;
                        text-decoration : none;
                    }
                }
            }
            > .rhs-container{
                width : 40%;
                background-color : #0197D6;
                border-radius : 0 15px 15px 0;
                margin : 18px 0;
                padding : 20px;position: relative;
                >.title{
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    max-height : 55px;width : 100% ; font-size: 20px; color: #fff; font-weight: 500;line-height : 28px;
                }
                >.description{
                    height: calc(100% - 230px);
                    overflow: auto;
                    // max-height: calc(100% - 210px);
                    position : relative;
                    > .descriptionPara{
                        min-height : 95%;
                        max-height : 95%;
                        font-size : 18px;
                        color : #fff;
                        font-weight : 400;
                        line-height : 24px;
                        overflow : auto;
                    }
                }
                .userName{
                    height: 48px;
                    width: 50%;
                    text-decoration: none;
                    border: 1px solid #0197D6;
                    border-radius: 24px;
                    font-size: 20px;
                    margin-right: 20px;
                    padding: 0 12px;
                }
                button {
                    cursor : pointer;
                    width : 166px;
                    height : 53px;
                    border-radius: 35px;
                    font-size : 22px; 
                    text-align: center;
                    background-color: #f8de4c;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                    border : none;
                    margin-top : 10px;

                }
            }
        }
    }
    
    > footer{
        position : absolute;
        bottom : 0;
        background-color : #8A8A8A;
        height : 30px;
        width : 100%;
        display : flex;
        align-items : center;
        color : white;
        font-size : 12px;
        box-shadow : 0 3px 6px rgba(0,0,0,0.16);
        text-align : left;
        padding : 0 30px; 

    }
 }

 .joinNowWithOpen{
    background-color: #0E6C9D;
    border-radius: 15px;
    padding: 15px;
    margin-top: 10px;
}
.joinNowWithClose{
    position: absolute;
    bottom: 38px;
}