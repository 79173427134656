.ChatInput {
	// position: relative;
	height: 53px;
	width: 100%;
	margin-top : 28px;
	background-color : #e9eaec;
	border-radius : 4px;
	display: flex;
    align-items: center;
	 textarea {
		height: 100%;
		width: calc(100% - 48px);
		padding: 10px 8px 4px 8px;
		resize: none;
		outline: none;
		background-color: rgba(#E9EAEC, 1);
		color: #363636;
		font-size: 14px;
		font-weight: 400;
		line-height: 13px; 
		border : none;
		border-radius : 4px;

		// placeholder() {
		// 	color : gray;
		// 	font-weight : 400;
		// }

		&:disabled {
			opacity: 0.65;
		}
	}
}
