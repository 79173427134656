.controlIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-left: 12px;

  
}
.handRaiseIcon {
    cursor: unset;
}

.kickOutIcon{
    height: 22px;
    width: 22px;
}
