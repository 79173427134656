.Peers {
  min-height: 100%;
  width: 100%;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  margin: 11px 9px 8px 11px;
  background: #152f4c;

  position: relative;
  overflow: hidden !important;
  // +desktop() {

  // 	padding:0 0 0 0;
  // 	display: flex;
  // 	flex-direction: row;
  // 	flex-wrap: wrap;
  // 	justify-content: center;
  // 	align-items: center;
  // 	align-content: center;

  // 	margin: 11px 9px 8px 11px;
  // 	background: #243B55;

  // 	position: relative;
  // }

  // +mobile() {
  // 	min-height: 100vh;
  // 	width: 100%;
  // 	display: flex;
  // 	flex-direction: column;
  // 	justify-content: center;
  // 	align-items: center;
  // }
  .backgroundImg {
    height: 100%;
    width: 100%;
    content: url("../../../../assets/BG-img.jpg");
  }

  .peer-container {
    overflow: hidden;

    // AppearFadeIn(1000ms);
    flex-flow: row wrap;
    height: 31%;
    width: 31%;
    margin: 6px;
    border: 1px solid rgba(#fff, 0.15);
    box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
    transition-property: border-color;
    transition-duration: 0.35s;

    &.active-speaker {
      border: 8px solid #0197d6 !important;
      border-radius: 12px 12px 0 0 !important;
    }
    &.nonactive-speaker {
      border: 8px solid #fff !important;
      border-radius: 12px 12px 0 0 !important;
    }
    &.active-speaker-border {
      border: 4px solid #0197d6 !important;
      border-radius: 12px 12px 0 0 !important;
    }
    &.nonactive-speaker-border {
      border: 4px solid #fff !important;
      border-radius: 12px 12px 0 0 !important;
    }

    // +desktop() {

    // 	flex-flow: row wrap
    // 	height: 31%;
    // 	width: 31%;
    // 	margin: 6px;
    // 	border: 1px solid rgba(#fff, 0.15);
    // 	box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
    // 	transition-property: border-color;
    // 	transition-duration: 0.35s;

    // 	&.active-speaker {
    // 		border : 8px solid #0197D6 !important;
    // 		border-radius : 12px 12px 0 0 !important;
    // 	}
    // 	&.nonactive-speaker{
    // 		border : 8px solid #fff !important;
    // 		border-radius : 12px 12px 0 0 !important;
    // 	}
    // 	&.active-speaker-border {
    // 		border : 4px solid #0197D6 !important;
    // 		border-radius : 12px 12px 0 0 !important;
    // 	}
    // 	&.nonactive-speaker-border{
    // 		border : 4px solid #fff !important;
    // 		border-radius : 12px 12px 0 0 !important;
    // 	}
    // }

    // +mobile() {
    // 	flex: 100 100 auto;
    // 	order: 2;
    // 	min-height: 25vh;
    // 	width: 100%;
    // 	display: flex;
    // 	flex-direction: column;
    // 	justify-content: center;
    // 	align-items: center;

    // 	&.active-speaker {
    // 		order: 1;
    // 	}
    // }
  }

  // > .controls {
  // 	position: absolute;
  // 	z-index: 10;
  // 	left:40%;
  // 	bottom:0;
  // 	display: flex;
  // 	flex-direction: row;
  // 	justify-content: flex-end;
  // 	align-items: center;
  // 	pointer-events: none;

  // 	> .button {
  // 		flex: 0 0 auto;
  // 		margin: 4px;
  // 		margin-left: 0;
  // 		border-radius: 2px;
  // 		pointer-events: auto;
  // 		background-position: center;
  // 		background-size: 75%;
  // 		background-repeat: no-repeat;
  // 		background-color: rgba(#000, 0.5);
  // 		cursor: pointer;
  // 		transition-property: opacity, background-color;
  // 		transition-duration: 0.15s;
  //         width: 36px;
  // 			height: 36px;
  // 			opacity: 0.55;

  // 			&:hover {
  // 				opacity: 1;
  // 			}

  // 		// +desktop() {
  // 		// 	width: 36px;
  // 		// 	height: 36px;
  // 		// 	opacity: 0.55;

  // 		// 	&:hover {
  // 		// 		opacity: 1;
  // 		// 	}
  // 		// }

  // 		// +mobile() {
  // 		// 	width: 26px;
  // 		// 	height: 26px;
  // 		// }

  // 		&.unsupported {
  // 			pointer-events: none;
  // 		}

  // 		&.disabled {
  // 			pointer-events: none;
  // 			opacity: 0.5;
  // 		}

  // 		&.on {
  // 			background-color: rgba(#fff, 0.85);
  // 		}

  // 		&.mic {
  // 			&.on {
  // 				background-image: url('/resources/images/icon_mic_black_on.svg');
  // 			}

  // 			&.off {
  // 				background-image: url('/resources/images/icon_mic_white_off.svg');
  // 				background-color: rgba(#d42241, 0.7);
  // 			}

  // 			&.unsupported {
  // 				background-image: url('/resources/images/icon_mic_white_unsupported.svg');
  // 			}
  // 		}

  // 		&.webcam {
  // 			&.on {
  // 				background-image: url('/resources/images/icon_webcam_black_on.svg');
  // 			}

  // 			&.off {
  // 				background-image: url('/resources/images/icon_webcam_white_on.svg');
  // 			}

  // 			&.unsupported {
  // 				background-image: url('/resources/images/icon_webcam_white_unsupported.svg');
  // 			}
  // 		}

  // 		&.change-webcam {
  // 			&.on {
  // 				background-image: url('/resources/images/icon_change_webcam_black.svg');
  // 			}

  // 			&.unsupported {
  // 				background-image: url('/resources/images/icon_change_webcam_white_unsupported.svg');
  // 			}
  // 		}

  // 		&.share {
  // 			&.on {
  // 				background-image: url('/resources/images/icon_share_black_on.svg');
  // 			}

  // 			&.off {
  // 				background-image: url('/resources/images/icon_share_white_on.svg');
  // 			}

  // 			&.unsupported {
  // 				background-image: url('/resources/images/icon_share_white_unsupported.svg');
  // 			}
  // 		}

  // 		&.close{
  // 			background-image:url('/resources/images/icon_close_black.svg');
  // 		}

  // 		&.user{
  // 			background-image:url('/resources/images/profile-user.png');
  // 		}
  // 	}
  // }

   .chat-input-container {
    position: fixed;
    z-index: 100;
    overflow: hidden;
    box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
    height: 30px;
    width: 25%;
    bottom: 5px;
    right: 20px;
    border: 1px solid rgba(#fff, 0.15);

    // +desktop() {
    // 	height: 30px;
    // 	width: 25%;
    // 	bottom: 5px;
    // 	right: 20px;
    // 	border: 1px solid rgba(#fff, 0.15);
    // }

    // +mobile() {
    // 	height: 30px;
    // 	width: 200px;
    // 	bottom: 10px;
    // 	left: 10px;
    // 	border: 1px solid rgba(#fff, 0.25);
    // }
  }
}
