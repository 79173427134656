$COLOR_BG_1: rgba(#243B55, 0.75);
$COLOR_BG_2 : rgba(#141E30, 0.65);
.Room {
	position: relative;
	height: calc(100% - 0px);
	width: calc(100% - 0px);
	//margin: 9px 9px 8px 9px;
	// AppearFadeIn(300ms);

	> .state {
		position: fixed;
		z-index: 100;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 25px;
		background-color: $COLOR_BG_1;
        top: 20px;
		left: 20px;
		width: 184px;

		// +desktop() {
		// 	top: 20px;
		// 	left: 20px;
		// 	width: 184px;
		// }

		// +mobile() {
		// 	top: 10px;
		// 	left: 10px;
		// 	width: 110px;
		// }

		> .icon {
			flex: 0 0 auto;
			border-radius: 100%;
            margin: 6px;
				margin-right: 0;
				height: 16px;
				width: 16px;

			// +desktop() {
			// 	margin: 6px;
			// 	margin-right: 0;
			// 	height: 16px;
			// 	width: 16px;
			// }

			// +mobile() {
			// 	margin: 4px;
			// 	margin-right: 0;
			// 	height: 16px;
			// 	width: 16px;
			// }

			&.new, &.closed {
				background-color: rgba(#aaa, 0.5);
			}

			&.connecting {
				animation: Room-info-state-connecting .75s infinite linear;
			}

			&.connected {
				background-color: rgba(#30bd18, 0.75);

				// +mobile() {
				// 	display: none;
				// }
			}
		}

		> .text {
			flex: 100 0 auto;
			user-select: none;
			pointer-events: none;
			text-align: center;
			text-transform: uppercase;
			font-family: 'Roboto';
			font-weight: 400;
			color: rgba(#fff, 0.75);
            font-size: 12px;

			// +desktop() {
			// 	font-size: 12px;
			// }

			// +mobile() {
			// 	font-size: 10px;
			// }

			// &.connected {
			// 	+mobile() {
			// 		display: none;
			// 	}
			// }
		}
	}

	// > .room-link-wrapper {
	// 	pointer-events: none;
	// 	position: absolute;
	// 	z-index: 1;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// 	display: flex;
	// 	flex-direction: row;
	// 	justify-content: center;

	// 	> .room-link {
	// 		width: auto;
	// 		background-color: $COLOR_BG_1;
	// 		border-bottom-right-radius: 4px;
	// 		border-bottom-left-radius: 4px;
	// 		box-shadow: 0px 3px 12px 2px rgba(#111, 0.4);

	// 		> a.link {
	// 			display: block;;
	// 			user-select: none;
	// 			pointer-events: auto;
	// 			text-transform: uppercase;
	// 			font-family: 'Roboto';
	// 			font-weight: 400;
	// 			color: rgba(#fff, 0.75);
	// 			cursor: pointer;
	// 			text-decoration: none;
	// 			transition-property: color;
	// 			transition-duration: 0.1s;

	// 			+desktop() {
	// 				padding: 10px 20px;
	// 				font-size: 12px;
	// 			}

	// 			+mobile() {
	// 				padding: 6px 10px;
	// 				font-size: 10px;
	// 			}

	// 			&:hover {
	// 				color: #fff;
	// 				text-decoration: underline;
	// 			}
	// 		}
	// 	}
	// }

	> .me-container {
		position: fixed;
		z-index: 100;
		overflow: hidden;
		box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
		transition-property: border-color;
		transition-duration: 0.2s;
		height : 190px;
		width : 248px;
		position : absolute;
		bottom : 0; 
		right : 20px;
        height: 190px;
			width: 248px;
			bottom: 20px;
			left: 20px;
			border: 1px solid rgba(#fff, 0.15);

		&.active-speaker { 
			border : 4px solid #0197D6 !important;
			border-radius : 8px 8px 0 0 !important;
		}

		// +desktop() {
		// 	height: 190px;
		// 	width: 248px;
		// 	bottom: 20px;
		// 	left: 20px;
		// 	border: 1px solid rgba(#fff, 0.15);
			
		// }

		// +mobile() {
		// 	height: 220px;
		// 	width: 200px;
		// 	bottom: 50px;
		// 	left: 10px;
		// 	border: 1px solid rgba(#fff, 0.25);
		// }
	}

	> .chat-input-container {
		position: fixed;
		z-index: 100;
		overflow: hidden;
		box-shadow: 0px 5px 12px 2px rgba(#111, 0.5);
        height: 30px;
			width: calc(30% - 4px);
			bottom: 5px;
			right: 20px;
			border: 1px solid rgba(#fff, 0.15);

		// +desktop() {
		// 	height: 30px;
		// 	width: 30% - 4px;
		// 	bottom: 5px;
		// 	right: 20px;
		// 	border: 1px solid rgba(#fff, 0.15);
		// }

		// +mobile() {
		// 	height: 30px;
		// 	width: 200px;
		// 	bottom: 10px;
		// 	left: 10px;
		// 	border: 1px solid rgba(#fff, 0.25);
		// }
	}

	> .sidebar {
		position: fixed;
		z-index: 101;
		top: calc(50% - 60px);
		height: 120px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
        left: 20px;
			width: 36px;
			margin-top: -60px;

		// +desktop() {
		// 	left: 20px;
		// 	width: 36px;
		// 	margin-top: -60px;
		// }

		// +mobile() {
		// 	left: 10px;
		// 	width: 32px;
		// 	margin-top: -80px;
		// }

		> .button {
			flex: 0 0 auto;
			margin: 4px 0;
			background-position: center;
			background-size: 75%;
			background-repeat: no-repeat;
			background-color: $COLOR_BG_1;
			cursor: pointer;
			transition-property: opacity, background-color;
			transition-duration: 0.2s;
			border-radius: 100%;
            height: 36px;
			width: 36px;

			// +desktop() {
			// 	height: 36px;
			// 	width: 36px;
			// }

			// +mobile() {
			// 	height: 32px;
			// 	width: 32px;
			// }

			&.on {
				background-color: rgba(#fff, 0.7);
			}

			&.disabled {
				pointer-events: none;
				opacity: 0.5;
				transition-duration: 0s;
			}

			&.hide-videos {
				background-image: url('../../../assets/icons/icon_video_white_on.svg');

				&.on {
					background-image: url('../../../assets/icons/icon_video_black_off.svg');
				}
			}

			&.mute-audio {
				background-image: url('../../../assets/icons/icon_volume_white_on.svg');

				&.on {
					background-image: url('../../../assets/icons/icon_volume_black_off.svg');
				}
			}

			&.end-meeting {
				background-image: url('../../../assets/icons/icon_meetingclose.svg');

				&.on {
					background-image: url('../../../assets/icons/icon_meetingclose.svg');
				}
			}

			&.restart-ice {
				background-image: url('../../../assets/icons/icon_restart_ice_white.svg');
			}
		}
	}

	> .controls {
		position: absolute;
		z-index: 12;
		bottom:0;
		display: flex;
		flex-direction: row;
		align-items: center;
		pointer-events: none;
    	justify-content: center;
    	background-color: #000000;
    	height: 70px;
		transition : 0.5s;
		transform : translateY(100%);

		> .button {
			flex: 0 0 auto;
			margin: 4px;
			margin-left: 0;
			margin-right : 24px;
			border-radius: 2px;
			pointer-events: auto;
			background-position: center;
			background-size: 75%;
			background-repeat: no-repeat;
			background-color: rgba(#000, 0.5);
			cursor: pointer;
			transition-property: opacity, background-color;
			transition-duration: 0.15s;
            width: 56px;
				height: 48px;
				
				&:hover {
					opacity: 1;
				}

			// +desktop() {
			// 	width: 56px;
			// 	height: 48px;
				
			// 	&:hover {
			// 		opacity: 1;
			// 	}
			// }

			// +mobile() {
			// 	width: 26px;
			// 	height: 26px;
			// }

			&.unsupported {
				pointer-events: none;
			}

			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}

			&.on {
				//background-color: rgba(#fff, 0.85);
			}

			&.mic {
				&.on {
				 text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 25px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/LSMeet_Voice-enable.svg');
					}
					background-color : #717272;
					&:hover{
						background-color : #003d58;
					}
				}

				&.off {
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 25px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/LSMeet_Voice-disable.svg');
					}
					background-color : red;
					
				}

				&.unsupported {
					opacity : 0.5;
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 25px;
    					width: 27px;
    					object-fit: contain;
						background-image : url('../../../assets/icons/LSMeet_Voice-disable.svg');
					}
					background-color : #717272;

				}
			}

			&.webcam {
				&.on {
					&.multi{
						>.image-container{
							height: 27px;
							width: 27px;
							background-repeat : no-repeat;
							object-fit: contain;
							background-image : url('../../../assets/icons/Multi-camera.svg');
						}	
					}
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/LSMeet_Video-Enable.svg');
					}
					background-color : #717272;
					&:hover{
						background-color : #003d58;
					}
				}

				&.off {
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/LSMeet_Video-disable.svg');
					}
					background-color : red;
				}

				&.unsupported {
					opacity : 0.5;
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/LSMeet_Video-disable.svg');
					}
					background-color : #717272;
				}
			}

			&.change-webcam {
				&.on {
					background-image: url('../../../assets/icons/icon_change_webcam_black.svg');
				}

				&.unsupported {
					background-image: url('../../../assets/icons/icon_change_webcam_white_unsupported.svg');
				}
			}
			
			&.fullScreen {
				&.on {
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image: url('../../../assets/icons/Full-screen.svg');
					}
					background-color : #717272;
					&:hover{ 
						background-color : #003d58;
					}
				}
				&.off{
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image: url('../../../assets/icons/exit-full-screen.svg');
					}
					background-color : #003d58;
					&:hover{ 
						background-color : #003d58;
					}
				}

				&.unsupported {
					background-image: url('../../../assets/icons/Full-screen.svg');
				}
			}

			&.share {
				&.on {

					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/Share-Video.svg');
					}
					background-color : #003d58;
					&:hover{
						background-color : #003d58;
					}

				}

				&.off {
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/Share-Video.svg');
					}
					background-color : #717272;
					&:hover{
						background-color : #003d58;
					}
				}

				&.unsupported {
					background-image: url('../../../assets/icons/icon_share_white_unsupported.svg');
				}
			}

			&.record-meeting{
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
					pointer-events : none;
					opacity : 0.5;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/LSMeet_Record-icon.svg');
					}
					background-color : #717272;
					&:hover{
						background-color : #003d58;
					}
				

				&.on {
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/LSMeet_Record-active.svg');
					}

				}
			}

			&.close{
				background-image:url('../../../assets/icons/icon_close_black.svg');
			}

			// &.user{
			// 	background-image:url('../../../assets/icons/profile-user.png');
			// }
			
			&.end-meeting {
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/end.svg');
					}
					background-color : red; 
			}
			&.leave-meeting {
					text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
					>.image-container{
						height: 27px;
    					width: 27px;
						background-repeat : no-repeat;
    					object-fit: contain;
						background-image : url('../../../assets/icons/Leave.svg');
					}
					background-color : red; 
			}
			&.maximize{ 
				cursor : pointer;
				    text-align: center;
    				display: flex;
    				justify-content: center;
    				align-items: center;
    				border-radius: 50%;
    				height: 41px;
    				width: 45px;
				>.image-container{
					background-image : url('../../../assets/icons/Participants.svg');
					height: 27px;
    				width: 27px;
					background-repeat : no-repeat;
    				object-fit: contain;
				}
				background-color : #717272;
				&:hover{
					background-color : #003d58;
				}
			}
			&.minimize{
				cursor : pointer;
				text-align: center;
    			display: flex;
    			justify-content: center;
    			align-items: center;
    			border-radius: 50%;
    			height: 41px;
    			width: 45px;
				>.image-container{
					background-image : url('../../../assets/icons/Participants.svg');
					height: 27px;
    				width: 27px;
					background-repeat : no-repeat;
    				object-fit: contain;
				}
				background-color : #003d58;
				&:hover{
					background-color : #003d58;
				}
			}
			&.whiteboard{
				cursor : pointer;
				text-align: center;
    			display: flex;
    			justify-content: center;
    			align-items: center;
				pointer-events : none;
				opacity : 0.5;
    			border-radius: 50%;
    			height: 41px;
    			width: 45px;
				>.image-container{
					background-image : url('../../../assets/icons/White-board.svg');
					height: 24px;
    				width: 27px;
					background-repeat : no-repeat;
    				object-fit: contain;
				}
				background-color : #717272;
				&:hover{
					background-color : #003d58;
				}
			}

			&.setting{
				cursor : pointer;
				text-align: center;
    			display: flex;
    			justify-content: center;
    			align-items: center;
				pointer-events : auto;
    			border-radius: 50%;
    			height: 41px;
    			width: 45px;
				>.image-container{
					background-image : url('../../../assets/icons/Setting-icon.svg');
					height: 24px;
    				width: 27px;
					background-repeat : no-repeat;
    				object-fit: contain;
					background-position : center;
				}
				background-color : #717272;
				&:hover{
					background-color : #003d58;
				}
			}

			&.handRaise{
				cursor : pointer;
				text-align: center;
    			display: flex;
    			justify-content: center;
    			align-items: center;
				pointer-events : auto;
    			border-radius: 50%;
    			height: 41px;
    			width: 45px;
				>.image-container{
					background-image : url('../../../assets/icons/White-Hand1.svg');
					height: 24px;
    				width: 27px;
					background-repeat : no-repeat;
    				object-fit: contain;
					background-position : center;
				}
				background-color : #717272;
				&:hover{
					background-color : #003d58;
				}
			}

			.enabled-menu-item{
				opacity:1;
				color:#003D58;
			}

			.disabled.menu-item{
				opacity:'0.5';
				color:'red';
			}
		}
	}
}

@keyframes Room-info-state-connecting {
	50% { background-color: rgba(orange, 0.75); }
}

