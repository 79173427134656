.pinScreenContainer{
    height: 90%;
    width: 90%;
    max-width: 90%;
    max-height:90%;
    border-radius: 4px;

    .borderPeer{
        height: 100%;
        width: 100%;
        border: 7px solid white;
        border-radius: 10px !important;
    }
}