.Peer {
  display: flex;
  flex: 100 100 auto;
  position: relative;
  height: 100%;
  width: 100%;

  // +mobile() {
  // 	display: flex;
  // 	flex-direction: column;
  // 	justify-content: center;
  // 	align-items: center;
  // }

  // .upperRightCorner {
  //   position: absolute;
  //   right: 0;
  //   z-index: 10;
  //   height: fit-content;
  //   width: fit-content;
  //   display: flex;

  //   .pinButton {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     background-color: black;
  //     height: fit-content;
  //     width: fit-content;
  //     padding: 3px 4px 3px 6px;
  //     border-bottom-left-radius: 16px;

  //     .pinIcon {
  //       height: 20px;
  //       width: 20px;
  //     }
  //   }
  // }

  .wavelength {
    display: flex;
    align-items: center;
    margin-left: 5px;
    @keyframes quiet {
      25% {
        transform: scaleY(0.6);
      }
      50% {
        transform: scaleY(0.4);
      }
      75% {
        transform: scaleY(0.8);
      }
    }

    @keyframes normal {
      25% {
        transform: scaleY(1);
      }
      50% {
        transform: scaleY(0.4);
      }
      75% {
        transform: scaleY(0.6);
      }
    }
    @keyframes loud {
      25% {
        transform: scaleY(1);
      }
      50% {
        transform: scaleY(0.2);
      }
      75% {
        transform: scaleY(1.2);
      }
    }
    .boxContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 26px;
      width: 36px;
    }

    .box {
      transform: scaleY(0.2);
      height: 65%;
      width: 5px;
      background: #7fff00;
      animation-duration: 1.2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      border-radius: 8px;
    }

    .box1 {
      animation-name: quiet;
    }

    .box2 {
      animation-name: normal;
    }

    .box3 {
      animation-name: quiet;
    }

    .box4 {
      animation-name: loud;
    }

    .box5 {
      animation-name: quiet;
    }
  }

  .indicators {
    width: 100%;
    height: 30px;
    background-color: "#000";
    bottom: "0";
    top: "unset";
    right: "unset";
    display: "flex";
    position: absolute;
    z-index: 10;
    align-items: center;
    padding: 0 12px;

    .icon {
      flex: 0 0 auto;
      margin: 4px;
      margin-right: 0;
      width: 32px;
      height: 32px;
      background-position: center;
      background-size: 75%;
      background-repeat: no-repeat;
      transition-property: opacity;
      transition-duration: 0.15s;
      display: flex;
      opacity: 0.85;
      // +desktop() {
      // 	opacity: 0.85;
      // }

      &.mic-off {
        background-image: url("../../../../assets/icons/LSMeet_Voice-disable.svg");
      }

      &.webcam-off {
        background-image: url("../../../../assets/icons/LSMeet_Video-disable.svg");
      }
      &.mic-on {
        background-image: url("../../../../assets/icons/LSMeet_Voice-enable.svg");
      }
      &.webcam-on {
        background-image: url("../../../../assets/icons/LSMeet_Video-Enable.svg");
      }
    }
  }

  .incompatible-video {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      padding: 6px 12px;
      border-radius: 6px;
      user-select: none;
      pointer-events: none;
      font-size: 15px;
      color: rgba(#fff, 0.55);
    }
  }
}

.peerViewContainer {
  position: relative;
  height: 100%;
  width: 100%;

  .upperRightCorner {
    position: absolute;
    right: 0;
    z-index: 10;
    height: fit-content;
    width: fit-content;
    display: flex;

    .pinButton {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      height: fit-content;
      width: fit-content;
      padding: 3px 4px 3px 6px;
      border-bottom-left-radius: 16px;

      .pinIcon {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.peerViewContainer:not(:last-child) {
  margin-right: 10px;
}
