.Notifications {
	position: fixed;
	z-index: 9999;
	pointer-events: none;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
    padding: 10px;
		width: 300px;

	// +desktop() {
	// 	padding: 10px;
	// 	width: 300px;
	// }

	// +mobile() {
	// 	padding: 4px;
	// 	width: 65%;
	// 	max-width: 220px;
	// }

	> .notification {
		pointer-events: auto;
		height : 80px;
		margin-top: 4px;
		border-radius: 6px 0 0 6px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		box-shadow: 0 7px 5px rgba(0, 0, 0, 0.16);
		background-color : #F1F1F1;
		position : relative;
        width : 268px;
		height : 82px;
        padding: 8px 8px 8px 8px;

		// +desktop() {
		// 	width : 268px;
		// 	height : 82px
		// }

		// +mobile() {
		// 	width: 100%;
		// }

		// &.Appear-appear {
		// 	visibility: hidden;
		// 	opacity: 0;
		// 	transition: all 0.15s ease-in-out 0s, visibility 0s linear 0.25s;
		// 	transform: translateX(200px);
		// }

		// &.Appear-appear.Appear-appear-active {
		// 	visibility: visible;
		// 	pointer-events: auto;
		// 	opacity: 1;
		// 	transform: translateY(0%);
		// 	transition-delay: 0s, 0s;
		// }

		// +desktop() {
		// 	padding: 8px 8px 8px 8px;
		// }

		// +mobile() {
		// 	padding: 6px 16px 6px 12px;
		// }

		> .icon {
			flex: 0 0 auto;
			height: 32px;
			width: 32px;
			margin-right: 12px;
			background-position: center;
			background-size: 100%;
			background-repeat: no-repeat;
			background: #F7BD1D;
    		border-radius: 50%;
		}

		> .body {
			position : relative;
			> .title {
				font-weight: 600;
				user-select: none;
				cursor: default;
				line-height: 1.35em;
				margin-bottom: 0;
				margin-top: 4px;
				padding-bottom : 4px;
                font-size: 14px;
				// +desktop() {
				// 	font-size: 14px;
				// }

				// +mobile() {
				// 	font-size: 12px;
				// }
			}

			> .text {
				user-select: none;
				cursor: default;
				line-height: 1.35em;
				color: #675B5B;
				font-weight : 600;
				margin-top : 5px;
				border : 1px solid #F7F7F7;
				background-color : white;
				border-radius: 6px;
				padding : 5px;
				display: -webkit-box;
    			-webkit-line-clamp: 2;
    			-webkit-box-orient: vertical;
    			overflow: hidden;
    			max-height: 3em;
                font-size: 14px; 
				margin-bottom: 2px;
				// +desktop() {
				// 	font-size: 14px; 
				// }

				// +mobile() {
				// 	font-size: 12px;
				// }
			}
			 button {
                cursor : pointer;
				display : flex;
				align-items : center;
				justify-content : center;
                width : 92px;
                height : 32px;
                border-radius: 16px;
                font-size : 14px;
                text-align: center;
                background-color: transparent;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
				border : none;
				margin-right : 10px;
				
                }
				.cancel{
					&:hover{
						color : red;
						border : 1px solid red;
					}
				}
				.accept{
					&:hover{
						color : #0197d6;
						border : 1px solid #0197d6;
					}
				}
		}

		&.info {
			>.icon {
				padding : 3px;
				.image-container{
					height : 100%;
					width : 100%;
					content: url('../../../assets/icons/icon_notification_info_white.svg');
				}
			}
		}

		&.error {
			
			color: #fff;

			>.icon {
				padding : 3px;
				.image-container{
					height : 100%;
					width : 100%;
					content: url('../../../assets/icons/icon_notification_error_white.svg');
				}
			}
		}
		
		&.unmuteMic{
			>.icon{
				padding : 3px;
				.image-container{
					height : 100%;
					width : 100%;
					content : url('../../../assets/icons/LSMeet_Voice-enable.svg');
				}
			}
		}

		&.muteMic{
			>.icon{
				padding : 3px;
				.image-container{
					height : 100%;
					width : 100%;
					content : url('../../../assets/icons/LSMeet_Voice-disable.svg');
				}
				}	
		}

		&.disableWebcam{
			>.icon{
				padding : 3px;
				.image-container{
					height : 100%;
					width : 100%;
					content : url('../../../assets/icons/LSMeet_Video-disable.svg')
				}
			}
		}

		&.enableWebcam{
			>.icon{
				padding : 3px;
				.image-container{
					height : 100%;
					width : 100%;
					content : url('../../../assets/icons/LSMeet_Video-Enable.svg')
				}
			}
		}
	}
}
