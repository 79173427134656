.ParticipantChat {
  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordion-root:before {
    opacity: 0;
  }
  .MuiIconButton-root {
    color: #fff;
  }
  .MuiAccordionDetails-root {
    padding: 8px 8px 16px;
  }
  .MuiCollapse-root {
    margin: 0 8px;
    border-left: 1px solid #e9eaec;
    border-right: 1px solid #e9eaec;
  }

  .MuiCollapse-entered {
    height: auto;
    overflow: visible;
    margin: 0 8px;
    border-left: 1px solid #e9eaec;
    border-right: 1px solid #e9eaec;
    background: white;
    overflow: auto;
  }
  .participant-chat-header {
    background-color: #0197d6;
    margin: 0 7px;
    border-radius: 8px 8px 0 0;
    height: 38px;
    max-height: 38px !important;
    min-height: 38px !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    color: #fff;
    font-size: 14px;

    .participant-chat-panel-icon {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 106px);
      .totalNum {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0be80b;
        border-radius: 50%;
        position: relative;
        top: 3px;
        margin-left: 6px;
        color: #363636;
        font-size: 12px;
        font-weight: 600;
      }
      .handRaiseIcon {
        width: 22px;
        height: 21px;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../../../assets/icons/White-Hand-yellow.svg");
      }
    }
  }
  .participantIcon {
    width: 28px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../assets/icons/Participants.svg");
  }
  .chatIcon {
    width: 28px;
    height: 27px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../assets/icons/Message-chat.svg");
  }

  .panelName {
    padding-left: 11px;
    position: relative;
    top: 7px;
    height: 100%;
  }

  .chat-input-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 84px;
    left: 0;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 0 10px 0 8px;
  }
}
